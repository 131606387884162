<template>
  <div class="mainpage">
    <ToolBar/>
    
    <v-carousel
    class="mainpage__carousel"
      cycle
      height="600"
      width="100%"
      hide-delimiter-background
      show-arrows-on-hover
    >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
    >
      <v-sheet
        class="mainpage__carousel__color"
        height="100%"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          
          <div class="display-2">
            <h4 class="mainpage__carousel__color__text1">Жаны чыгарылыш</h4>
            <h3 class="mainpage__carousel__color__text2">ЖАШТАР ПОДКАСТЫ</h3>
            <v-btn class="mainpage__carousel__color__text3"><h5>Шилтеме</h5></v-btn>
          </div>
          
          <img class="mainpage__carousel__img" src="../../assets/beats.png"/>
        </v-row>
        
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
  <div class="mainpage__jrt">
    <h4 class="mainpage__jrt__title">ЖАЛПЫ РЕСПУБЛИКАЛЫК ТЕСТИРЛӨӨ</h4>
    <v-row>
      <v-col cols="12" md="6" sm="6" >
          <h4 class="mainpage__jrt__p">Өлкө аймагында бүгүн, 18-майда Жалпы республикалык тестирлөө башталды. Сынактар 18-19-майда жана 21-22-майда өткөрүлөт. Биринчи этапта жалпы тест өтсө, экинчи этапта тандай турчу кесиптеринин негизинде предметтик тест өтөт.Билимди баалоо жана окутуу усулдары  борбору маалымдагандай, өлкө аймагы боюнча тест учурунда 114 борбор иштейт. Маалыматка ылайык, буга чейин каттоодон 41 миң 286 абитуриент каттоодон өткөн. 17-20-майда боло турган кошумча каттоодон кийин бул сандар өзгөрүшү мүмкүн. Ошондой эле, тестти жүргүзгөн борбордун билдиришинче, июнь айында май айында жетише албай калгандар үчүн да өзүнчө сынак болот.</h4>
          <v-btn class="mainpage__jrt__p__btn" color="#FFBD59">Тоолук маалымат</v-btn>
          <h4 class="mainpage__jrt__p__text">Биздеги мумкунчулуктор</h4>
          <img class="mainpage__jrt__p__img" src="../../assets/student_icon.png" alt="">
        </v-col>
        <v-col md="6" sm="6" align="center">
          <img class="mainpage__jrt_img" src="../../assets/student.png" />
        </v-col>
    </v-row>
  </div>
  <div class="mainpage__course">
    <v-row>
      <v-col cols="12" md="4" sm="6" xs="12" align="center">
        <div>
          <img
            class="mainpage__course__img"
            src="../../assets/course_img2.png"
            alt="Red dot"
          >
          <h4 class="mainpage__course__text3">Логика</h4>
          <v-btn class="mainpage__course__btn"><v-icon>mdi-video</v-icon>Видео сабак</v-btn>
          <h4 class="mainpage__course__text3">Жалпы 20 видео сабак</h4>
          <v-btn class="mainpage__course__btn"><v-icon>mdi-book</v-icon>ТЕСТТЕР</v-btn>
          <h4 class="mainpage__course__text3">Болум боюнча тесттер</h4>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="6" xs="12" align="center">
        <div>
          <img
            class="mainpage__course__img"
            src="../../assets/course_img3.png"
            alt="Red dot"
          >
          <h4 class="mainpage__course__text3">Грамматика</h4>
          <v-btn class="mainpage__course__btn"><v-icon>mdi-video</v-icon>Видео сабак</v-btn>
          <h4 class="mainpage__course__text2">Жалпы 20 видео сабак</h4>
          <v-btn class="mainpage__course__btn"><v-icon>mdi-book</v-icon>ТЕСТТЕР</v-btn>
          <h4 class="mainpage__course__text3">Болум боюнча тесттер</h4>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="6" xs="12" align="center">
        <div>
          <img
            class="mainpage__course__img"
            src="../../assets/course_img1.png"
            alt="Red dot"
          >
          <h4 class="mainpage__course__text1">Окуу тушуну</h4>
          <v-btn class="mainpage__course__btn"><v-icon>mdi-video</v-icon>Видео сабак</v-btn>
          <h4 class="mainpage__course__text2">Жалпы 20 видео сабак</h4>
          <v-btn class="mainpage__course__btn"><v-icon>mdi-book</v-icon>ТЕСТТЕР</v-btn>
          <h4 class="mainpage__course__text3">Болум боюнча тесттер</h4>
        </div>
      </v-col>
    </v-row>
  </div>
  <div class="mainpage__achievement">
    <v-row>
      <v-col cols="12" md="3" sm="6" xs="12" align="center">
        <img class="mainpage__achievement__img1" src="../../assets/achievement1.png" alt="">
        <h2 class="mainpage__achievement__number"><span class="counter">2098</span></h2>
        <h4 class="mainpage__achievement__text">Бутуруучу</h4>
      </v-col>
      <v-col cols="12" md="3" sm="6" align="center">
        <img class="mainpage__achievement__img" src="../../assets/achievement2.png" alt="">
        <h2 class="mainpage__achievement__number"><span class="counter">220</span></h2>
        <h4 class="mainpage__achievement__text">Алдынкы упай</h4>
      </v-col>
      <v-col cols="12" md="3" sm="6" align="center">
        <img class="mainpage__achievement__img" src="../../assets/achievement3.png" alt="">
        <h2 class="mainpage__achievement__number"><span class="counter">200</span></h2>
        <h4 class="mainpage__achievement__text">Алдынкы студент</h4>
      </v-col>
      <v-col cols="12" md="3" sm="6" align="center">
        <img class="mainpage__achievement__img" src="../../assets/achievement4.png" alt="">
        <h2 class="mainpage__achievement__number"><span class="counter">100</span></h2>
        <h4 class="mainpage__achievement__text">Чет олколук студент</h4>
      </v-col>
    </v-row>
  </div>
  <div class="mainpage__banner">
    <img class="mainpage__banner__img" src="../../assets/english.png"/>
  </div>
  <div class="mainpage__charts">
    <h2 class="mainpage__charts__title">АНГЛИС ТИЛИН УЙРОНУУ КУРСТАРЫ</h2>
    <v-row>
      <v-col cols="12" md="4" sm="4" xs="12" align="center">
        <img class="mainpage__charts__img" src="../../assets/beginner.png" alt="">
        <h1>BEGINNER LEVEL</h1>
        <ul class="mainpage__charts__ul">
          <li>20 сабак</li>
          <li>Zoom тиркемеси</li>
          <li>Сертификат</li>
          <li>Суйлошмо клуб</li>
          <li>Европадагы мугалим</li>
          <li>жана башка...</li>
        </ul>
        <v-btn class="mainpage__charts__btn" color="#FFBD59">Курска жазылуу</v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" xs="12" align="center">
        <img class="mainpage__charts__img" src="../../assets/intermediate.png" alt="">
        <h1>ELEMENTARY LEVEL</h1>
        <ul class="mainpage__charts__ul">
          <li>20 сабак</li>
          <li>Zoom тиркемеси</li>
          <li>Сертификат</li>
          <li>Суйлошмо клуб</li>
          <li>Европадагы мугалим</li>
          <li>жана башка...</li>
        </ul>
        <v-btn class="mainpage__charts__btn" color="#FFBD59">Курска жазылуу</v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" xs="12" align="center">
        <img class="mainpage__charts__img3" src="../../assets/advance.png" alt="">
        <h1>PRO-INTERMEDIATE LEVEL</h1>
        <ul class="mainpage__charts__ul">
          <li>20 сабак</li>
          <li>Zoom тиркемеси</li>
          <li>Сертификат</li>
          <li>Суйлошмо клуб</li>
          <li>Европадагы мугалим</li>
          <li>жана башка...</li>
        </ul>
        <v-btn class="mainpage__charts__btn" color="#FFBD59">Курска жазылуу</v-btn>
      </v-col>
    </v-row>
  </div>
</div>
</template>



<script>
import ToolBar from '../../components/toolbar/ToolBar';
  export default {
    name: 'mainPage',
    components:{
      ToolBar
    },
    data: () => ({
      colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
    }),
  }
</script>
<style lang="scss" scoped>
.mainpage{
  &__miniheader{
    &.v-bottom-navigation{
      background: linear-gradient(to left, #FCB75F 10%, #B428FA 100%);
      box-shadow: none;
    }
    &__icons{
      margin: 15px 100px 0 0;
      color:#fff;
    }
  }
  &__header {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    background: #ffffff;
    margin-top:55px;
    height: 20px;
    display: flex;
    align-items: center;
    position: fixed;
    color:black;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    -webkit-box-shadow: inset 0 0 6px #d1d1d4;
    &__avatar{
        &__img{
          width:200px;
          margin:10px 0px 0px 40px;
        }
    }
    &__header{
      background: linear-gradient(to left, #FCB75F 10%, #B428FA 100%);
      box-shadow: none;
      &__icons{
        margin: 10px 0px 0 0;
        color:#fff;
      }
    }
    &__header2{
      background-color:#fff;
    }
    &__menu{
      &__list{
        height: 50px;
        
        &__btn{
          margin:0px 40px 10px 0px;
          font-size:16px;
          font-weight:450;
          &__icon{
            margin-right:5px;
          }
        }
      }
      
    }
  }
  &__carousel{
    margin-top:125px;
    &__img{
      
      height:450px;
      padding:10px;
    }
    &__color{
      background: linear-gradient(to right, #f5d06a 0%, #e6a819 100%);
      &__text1{
        color:#ffffff;
        
      }
      &__text2{
      color:black;
      
      }
      &__text3{
        color:rgb(0, 0, 0);
      }
    }
  }
  &__jrt{
    margin:20px;
    &__title{
      color:#FFBD59;
      font-size:30px;
      display:flex;
      justify-content:center;
      margin-bottom:10px;
    }
    &__p{
      padding: 50px 0px 0px 100px;
      &__btn{
        margin:0px 0px 0px 70%;
      }
      &__text{
        padding: 50px 0px 0px 100px;
      }
      &__img{
        margin:10px 0px 0px 100px;
        width:300px;
      }
    }
  }
  &__course{
    background-color:#FFBD59;
    &__img{
      width:70%;
    }
    &__text1{
      color:#ffffff;
      font-size:20px;
      margin:10px;
    }
    &__text2{
      color:#ffffff;
      font-size:20px;
      margin:10px;
    }
    &__text3{
      color:#ffffff;
      font-size:20px;
      margin:10px;
    }
  }
  &__achievement{
    &__img{
      margin:20px;
      width:30%;
      padding:10px;
    }
    &__img1{
      margin:20px;
      width:90px;
      padding:10px;
    }
    &__number{
      font-size:60px;
      font-weight:700;
      color:#FFBD59;
    }
    &__text{
      font-size:20px;
      font-weight:700;
    }
  }
  &__banner{
    &__img{
      width:100%;
    }
  }
  &__charts{
    &__title{
      color:#FFBD59;
      display:flex;
      justify-content: center;
      margin:10px;
      font-size:40px;
    }
    &__img{
      width:40%;
    }
    &__img3{
      width:35%;
    }
    &__ul{
      list-style-type: none;
      margin:10px;
      font-weight:600;
    }
    &__btn{
      color:#fff;
    }
  }
}
@media (max-width: 540px) {
  .mainpage{
    width:100%;
  &__miniheader{
    &.v-bottom-navigation{
      background: linear-gradient(to left, #FCB75F 10%, #B428FA 100%);
      box-shadow: none;
    }
    &__icons{
      margin: 15px 100px 0 0;
      color:#fff;
    }
  }
  &__header {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    background: #ffffff;
    margin-top:55px;
    height: 20px;
    display: flex;
    align-items: center;
    position: fixed;
    color:black;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    -webkit-box-shadow: inset 0 0 6px #d1d1d4;
    &__avatar{
        &__img{
          width:200px;
          margin:10px 0px 0px 40px;
        }
    }
    &__header{
      background: linear-gradient(to left, #FCB75F 10%, #B428FA 100%);
      box-shadow: none;
      &__icons{
        margin: 10px 0px 0 0;
        color:#fff;
      }
    }
    &__header2{
      background-color:#fff;
    }
    &__menu{
      &__list{
        height: 50px;
        
        &__btn{
          margin:0px 40px 10px 0px;
          font-size:16px;
          font-weight:450;
          &__icon{
            margin-right:5px;
          }
        }
      }
      
    }
  }
  &__carousel{
    margin-top:255px;
    &__img{
      
      height:450px;
      padding:10px;
    }
    &__color{
      background: linear-gradient(to right, #f5d06a 0%, #e6a819 100%);
      &__text1{
        color:#ffffff;
        font-size:20px;
      }
      &__text2{
      color:black;
      font-size:16px;
      
      }
      &__text3{
        color:rgb(0, 0, 0);
        margin:0 auto;
      }
    }
  }
  &__jrt{
    
    &__title{
      color:#FFBD59;
      font-size:24px;
      display:flex;
      justify-content:center;
      margin: 10px 0px 10px 20px;
    }
    &__p{
      padding: 10px 10px 10px 10px;
      &__btn{
        margin:0px 0px 0px 10%;
      }
      &__text{
        padding: 50px 0px 0px 10px;
      }
      &__img{
        margin:10px 0px 0px 10px;
        width:200px;
      }
    }
  }
  &__course{
    background-color:#FFBD59;
    &__img{
      width:80%;
    }
    &__text1{
      color:#ffffff;
      font-size:20px;
      margin:10px;
    }
    &__text2{
      color:#ffffff;
      font-size:20px;
      margin:10px;
    }
    &__text3{
      color:#ffffff;
      font-size:20px;
      margin:10px;
    }
  }
  &__achievement{
    &__img{
      margin:20px;
      width:100%;
      padding:10px;
    }
    &__img1{
      margin:0 auto;
      width:90%;
      padding:10px;
    }
    &__number{
      font-size:70px;
      font-weight:700;
      color:#FFBD59;
    }
    &__text{
      font-size:20px;
      font-weight:700;
      margin-bottom:30px;
    }
  }
  &__banner{
    &__img{
      width:100%;
    }
  }
  &__charts{
    &__title{
      color:#FFBD59;
      display:flex;
      justify-content: center;
      margin:10px;
      font-size:30px;
    }
    &__img{
      width:40%;
    }
    &__img3{
      width:35%;
    }
    &__ul{
      list-style-type: none;
      margin:10px;
      font-weight:600;
    }
    &__btn{
      color:#fff;
    }
  }
}
}

</style>